.top-bar.ant-layout-header {
  height: 55px;
  background: #fff;
  padding-left: 0px !important;
}
.header-container,
.header-container .ant-col {
  height: 55px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.circle {
  width: 48px;
  height: 48px;
  margin-right: 8px;
  border-radius: 50%;
  color: black;
  display: grid;
  place-items: center;
}

.circle:hover {
  background-color: rgba(22, 51, 0, 0.12);
}

.circle .ant-badge {
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  width: 25px;
  height: 25px;
  background-color: blue;
  top: 0px;
}
.circle .ant-badge sup {
  font-size: 10px;
  border-radius: 0px;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  position: relative;
  display: block;
  left: 0px;
  top: 6px;
  padding: 3px;
}

.profile-name {
  align-items: center;
  border-radius: var(--radius-small);
  display: flex !important;
  height: 45px;
  border-radius: 10px;
  padding: 16px;
  justify-content: space-between;
}

.profile-name:hover {
  background-color: rgba(22, 51, 0, 0.08);
  cursor: pointer;
}

.profile-name .circle {
  margin: 0px;
  margin-right: 8px;
}

.profile-name .tw-chevron {
  display: inline-block;
}

.profile-name .tw-chevron svg {
  position: relative;
  top: 5px;
}

.profile-name.ant-dropdown-open {
  background-color: rgba(22, 51, 0, 0.08);
}

.profileDropdown {
  z-index: 6;
  box-shadow: 0 0 40px rgba(69, 71, 69, 0.2);
  padding: 24px;
  border-radius: 24px;
  background-color: #fff;
  min-width: 200px;
}

.profileDropdown a {
  color: black;
}

.profileDropdown a:hover {
  font-weight: 700;
  color: black;
}

.profile-menu-item {
  padding: 10px;
}
