.ant-layout-sider {
  background-color: #fff !important;
  margin-right: 1px;
}

.generic-menu-item {
  align-items: center;
  color: #272d39 !important;
  display: flex !important;
  font-size: 16px;
  gap: 16px;
  justify-items: center;
  line-height: 24px;
  padding: 16px;
  text-decoration: none !important;
}

.generic-menu-item.active {
  font-weight: bold;
}

.generic-menu-item:hover {
  font-weight: bold;
}

.generic-menu-item .icon-container {
  width: 29px !important;
  height: 29px !important;
}

.menu-section {
  padding: 16px 0px 48px 16px;
}
.generic-menu-item.active {
  font-weight: bold;
}

.icon-container i {
  position: relative;
  top: 1px;
  left: 1px;
}
.icon-container {
  border-left: 1px solid rgba(175, 175, 175, 0);
  border-top: 1px solid rgba(175, 175, 175, 0);
  transition: background-color 0.5s ease;
  border-radius: 50%;
  padding: 2px;
  width: 29px !important;
  height: 29px !important;
  min-width: 29px !important;
}

.active .icon-container {
  background-color: rgba(13, 104, 179, 0.21);
  width: 29px !important;
  height: 29px !important;
}

/* .generic-menu-item:hover .icon-container
{
  background-color:rgba(13, 104, 179, 0.21);
  border-left:1px solid rgba(13, 104, 179,0);
  border-top:1px solid rgba(13, 104, 179, 0);
  width:29px!important;
  height:29px!important;
  
} */
.menu-section-container {
  background-color: #fff;
}

.ant-layout-sider:not(.ant-layout-sider-collapsed) .menu-section-container {
  -webkit-box-shadow: 14px 0px 24px -17px rgba(66, 68, 90, 0.19);
  -moz-box-shadow: 14px 0px 24px -17px rgba(66, 68, 90, 0.19);
  box-shadow: 14px 0px 24px -17px rgba(66, 68, 90, 0.19);
}

.profile-name.ant-dropdown-trigger .icon-container {
  width: auto !important;
  height: auto !important;
}
