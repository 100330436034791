.nav-sidebar-brand {
  background-color: #fff;
  height: 55px;
  margin-left: 20px;
  align-items: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.nav-sidebar-brand img {
  width: 30px;
}
