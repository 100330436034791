@import "../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-richtexteditor/styles/bootstrap5.css";

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logo-img {
  height: 50px;
  margin: 13px;
}

.main-content {
  background: white;
}

.App-link {
  color: #61dafb;
}

.ant-btn.user-menu-button {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.ant-btn.sync-menu-button {
  position: absolute;
  bottom: 40px;
  left: 10px;
}

.bottom-menu-item-img {
  width: 24px;
  height: 28px;
  opacity: 0.6;
}

.antd-component-icon {
  opacity: 0.6;
  transition:
    transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) !important;
}

.bottom-menu-item-icon {
  font-size: 23px;
}

.ant-menu-submenu.minimized ul.ant-menu .ant-menu-item {
  padding-left: 24px !important;
}

/* General Scroll css Start */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(179, 218, 255, 0.9);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #80c2ff;
}

::-webkit-scrollbar-thumb {
  background-color: #80c2ff;
}

.space-between-menu-tabs {
  margin-bottom: 0 !important;
}

.space-between-menu-tabs:first-of-type {
  margin-top: 0 !important;
}

.d-flex {
  display: flex;
}

.cursor-pointer {
  cursor: pointer;
}

.padding-submenu {
  padding-left: 24px !important;
}

.ag-drag-handle {
  height: 38px;
  line-height: 38px;
}

.sidebar-menuItem-padding {
  padding: 18px 0 60px 24px !important;
  border-bottom: 1px solid #70809059;
}
.ant-menu-submenu.sidebar-menuItem-padding {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.ant-menu-submenu-inline {
  padding-left: 0 !important;
}

.ant-menu .ant-menu-dark .ant-menu-root .ant-menu-inline {
  position: relative;
}
ul.ant-menu-dark {
  margin-top: 0 !important;
}

.text-center {
  text-align: center !important;
}

.strategy-section-navigation-button {
  color: gray;
  font-size: 16px;

  &:hover {
    color: black;
  }
}

.asana-task-navigation-button {
  color: gray;
  font-size: 12px;
  padding-left: 3px;

  &:hover {
    color: black;
  }
}

.ql-editor pre.ql-syntax,
.ql-editor code {
  background-color: #2a2b2d !important;
  color: #f1bd6c !important;
}
.ql-tooltip {
  z-index: 100;
}