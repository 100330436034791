html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

#root {
  height: 100%;
}

.ant-tabs-content {
  height: 100%;
}

.ant-tabs-bar.ant-tabs-top-bar {
  margin-bottom: 0px;
}

.ant-tabs-tabpane {
  height: 100%;
}

.ant-tabs-nav {
  margin: 0 !important;
  padding-left: 1rem;
}

.ag-theme-material {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;

  --ag-material-accent-color: #1890ff !important;
  --ag-checkbox-unchecked-color: #999;

  .ag-checkbox-input-wrapper {
    font-size: 16px !important;
    line-height: 16px !important;
    width: 16px !important;
    height: 16px !important;
  }

  .ag-checkbox-input-wrapper:focus-within {
    box-shadow: none !important;
  }
}

.ag-theme-material .ag-cell {
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee !important;
  border-top: 0.5px solid #fff;
  border-left: 0.5px solid #fff;
  font-size: 13px;
  font-weight: normal;
  padding-right: 5px;
  padding-left: 10px;
}

.ag-row.ag-edit-row {
  border: 1px solid #1890ff !important;
}

.ag-theme-material .ag-cell-inline-editing {
  padding: 1px !important;
  font-size: 13px;
  height: 38px !important;
}

.ag-theme-material .ag-cell-inline-editing {
  background: white !important;
}

.ag-horizontal-left-spacer {
  overflow-x: auto;
}

.ag-theme-material .ag-cell-focus {
  border: 1px solid black !important;
}

.ag-theme-material .ag-header-cell {
  border-bottom: 0.5px solid #eee;
  border-right: 1px solid #eee;
  border-top: 0.5px solid #eee;
  border-left-color: #fff;
  font-size: 14px;
  font-weight: normal;
  padding-right: 5px !important;
  padding-left: 10px !important;
}

.ag-theme-material .ag-header-row-column .ag-header-cell {
  background: #fbfbfb;
}

.ag-cell-inline-editing .ag-cell-wrapper {
  position: absolute;
  left: 0;
}

.ag-action-header {
  color: transparent;
  padding-left: 18px;
  margin-left: 10px;
}

.ag-header-viewport {
  background-color: white;
}

.ag-theme-material .ag-header {
  border-bottom-color: transparent !important;
}

.ag-theme-material input[class^="ag-"][type="text"] {
  --ag-border-color: transparent;
  padding-bottom: 0 !important;
}

.ag-theme-material input[class^="ag-"][type="text"]:focus {
  border-color: transparent !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo-img {
  background-image: url("./images/sites123.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.main-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.autosizer-wrapper>div:not(.resize-triggers) {
  height: 1px !important;
  width: 100% !important;
}

.ant-btn-link {
  padding: 0 7px;
}

button {
  transition: 0.5s all ease-in-out;
  transform: scale(0.97);
}

.ant-btn-link:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

button:hover {
  transform: scale(1);
}

#root .ant-select-selection,
#root .ant-select,
#root .ant-input {
  border-radius: 0;
}

#root .ant-select-selection__rendered {
  line-height: 23px;
}

#root .ant-select,
#root .ant-input {
  height: 25px;
}

#root .ant-select-selection--single {
  height: 23px;
}

.ant-dropdown-menu-item>a,
.ant-dropdown-menu-submenu-title>a {
  color: #000;
}

.ag-unselectable {
  user-select: text !important;
}

.ag-font-style {
  user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  -webkit-user-select: text !important;
}

.ag-root {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.ag-acknowledge-cell {
  width: 100%;
  margin-top: 4px;
  padding: 0;
  padding-left: 0 !important;
}

.ag-acknowledge-cell .ag-cell-wrapper {
  justify-content: center;
}

.ant-select.read-only {
  pointer-events: none;
}

.margin-right-form-message {
  margin-right: 30px;
}

.scanned-emails {
  max-width: 300px;
}

.form-settings-topbar .ant-btn,
.form-settings-topbar .ant-btn:hover,
.link-showmore {
  height: auto;
  background: transparent;
  border: 0;
  padding: 0;
  line-height: normal;
  color: #40a9ff;
  box-shadow: none;
}

.justify-content-between {
  justify-content: space-between;
}

.scanned-form-control {
  max-width: 500px;
}

.mt-3 {
  margin-top: 1.5rem;
}

.span-width {
  width: 420px;
}

.scaaned-email-label {
  margin: 0px 13px 0px 43px;
}

.value-container {
  margin-bottom: 7px;
}

.form-setting-subheading {
  font-size: 16px;
  font-weight: 500;
  color: gray;
  margin-bottom: 1rem;
}

.d-block {
  display: block;
}

.search-bar-maring {
  margin: 12px 0px 12px 0px;
}

.campaign-filter-container {
  max-height: calc(60vh - 120px);
  overflow: auto;
}

.m-0 {
  margin: 0 !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.position-relative {
  position: relative;
}

.d-inline {
  display: inline !important;
}

.anticon.info-header-icon {
  position: relative;
  display: inline-block;
  top: -9px;
  left: 10px;
}

.text-center {
  text-align: center;
}

.p-0 {
  padding: 0px !important;
}

.messageBody {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  display: inline-block !important;
  max-width: 141 !important;
  vertical-align: middle !important;
}

.wideScreen {
  position: relative;
  bottom: 10px;
}

.d-flex {
  display: flex !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center;
}

.w-100 {
  width: 100%;
}

.pe-2 {
  padding-right: 0.5rem;
}

.traffic-sources-container {
  border: 1px solid rgb(128, 126, 126);
  border-radius: 2px;
}

.traffic-sources-container tr:nth-child(even) {
  background: #e9e9e9 !important;
}

.traffic-sources-container tbody tr td {
  border-right: 1px solid black !important;
}

.user-count .ant-card-body {
  padding: 24px;
  padding-top: 0;
}

.user-count .ant-card {
  border-radius: 10px;
}

.user-count .ant-card-body .ant-card .ant-card-body {
  padding: 0;
}

.user-count .ant-card-body .ant-statistic {
  padding-top: 24px;
  border-top: solid 4px transparent;
}

.user-count .ant-card-body .ant-statistic.selected {
  border-top: solid 4px #1890ff;
}

.text-success {
  color: green;
}

.user-count-status {
  font-weight: 500;
  font-size: 12px;
}

.component-heading {
  margin: 30px 0;
  color: #0066cc;
}

.traffic-sources-table .ant-row:nth-child(even) {
  background: rgb(000, 000, 000, 0.03);
}

.traffic-sources-table .ant-row:nth-child(odd) {
  background: #fff;
}

.traffic-sources-table .ant-row .ant-col {
  padding: 5px;
  border-top: solid 1px rgb(000, 000, 000, 0.1);
}

.mb-0 {
  margin-bottom: 0;
}

.ccTable table,
th,
td {
  border: 1px solid;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
}

.table td,
.table th {
  padding: 5px 15px 5px 5px;
  vertical-align: top;
  border: 1px solid #dee2e6;
}

.table td {
  font-size: 16px;
}

.table th {
  border-top: solid 1px #fff;
}

.table th:first-child,
.table td:first-child {
  border-left: solid 1px #fff;
}

.table th:last-child,
.table td:last-child {
  border-right: solid 1px #fff;
}

.table tbody tr:nth-of-type(odd) {
  background-color: rgb(000, 000, 000, 0.03);
}

.font-weigth-normal {
  font-weight: normal;
}

.table-title {
  color: rgb(0, 102, 204);
  cursor: pointer;
}

.pl-22 {
  padding-left: 22px;
}

.chart-heading {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.65);
}

.traffic-source-title {
  color: rgba(0, 0, 0, 0.65);
}

.user-count .ant-statistic-title {
  font-weight: 700;
}

.no-border.ag-cell-focus {
  border: 1px solid #eee !important;
  outline: none;
}

.ant-layout {
  background-color: #fff;
}

.ant-menu-inline {
  border: 0px;
  display: block;
}

.ant-layout {
  background-color: #fff;
}

.ant-menu-inline {
  border: 0px;
  display: block;
}

.ant-layout.ant-layout-has-sider>.ant-layout,
.ant-layout.ant-layout-has-sider>.ant-layout-content {
  overflow-x: visible;
}

.ag-theme-material .ag-ltr .ag-row-drag,
.ag-theme-material .ag-ltr .ag-selection-checkbox,
.ag-theme-material .ag-ltr .ag-group-expanded,
.ag-theme-material .ag-ltr .ag-group-contracted {
  margin-right: 2px !important;
}

.ag-theme-material .ag-ltr .ag-header-select-all {
  margin-right: 2px !important;
}

.ag-status-bar {
  height: 35px;
}

.ag-theme-material .ag-status-name-value {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.ag-theme-material .ag-ltr .ag-row-group-leaf-indent {
  margin-left: 0px !important;
}

.ag-theme-material .ag-row.ag-row-level-0.ag-row-group {
  background-color: #f3f3f3 !important;
}